<template>
	<div class="page edit-page">
		<div class="top-wrapper">
			<div class="title"> {{ 85 > scrollTop ? title : ''}} </div>
			<div class="actions">
				<el-button type="primary" plain size="small" class="cancel" @click="goBack"> 取消 </el-button>
				<el-button type="primary" size="small" class="submit" @click="submit"> 确定 </el-button>
			</div>
		</div>

		<div class="content-wrapper">
			<el-row :gutter="20" type="flex" class="content-row">
				<el-col :lg="15" :md="15" :sm="15" :xs="24">
					<div class="form-container pa20 pb0">
						<el-form ref="form" :model="form" :rules="rules">
							<el-form-item label="公告主题" prop="subject">
								<el-input v-model="form.subject" placeholder="请输入公告主题" maxlength="100" show-word-limit
									class="show-word-pr-3" @blur="form.subject=form.subject.trim()"></el-input>
							</el-form-item>

							<div class="inline-form-item">
								<el-form-item label="区域" prop="areaIdList">
									<el-select v-model="form.areaIdList" filterable multiple placeholder="请选择">
										<el-option v-for="option in areaOptions" :key="option.id" :label="option.name"
											:value="option.id"></el-option>
									</el-select>
								</el-form-item>

								<el-form-item label="分类" prop="type">
									<el-select v-model="form.type" filterable placeholder="请选择">
										<el-option v-for="option in typeOptions" :key="option.id" :label="option.name"
											:value="option.id"></el-option>
									</el-select>
								</el-form-item>
							</div>

							<el-form-item label="公告内容" prop="content" class="editor-wrapper">
								<RichTextEditor :content.sync="form.content" placeholder="请输入公告内容"></RichTextEditor>
							</el-form-item>

							<el-form-item label="涉及机型" prop="deviceIdList">
								<el-cascader :options="machineOptions" :props="machineProps" :show-all-levels="false"
									v-model="form.deviceIdList" clearable @visible-change="deviceCascaderVisableChange"
									:popper-class="deviceClass" class="full-width"></el-cascader>
							</el-form-item>

							<el-form-item label="固件下载地址">
								<div class="inline-form-item full-width">
									<el-form ref="firmware-form" :model="downloadItem" :rules="firmwareRules"
										class="firmware-form">
										<el-form-item>
											<el-input v-model="downloadItem.firmwareName" placeholder="请输入文字描述"
												clearable class="mr10 show-word-clearable-pr-2" maxlength="50"
												show-word-limit
												@blur="downloadItem.firmwareName=downloadItem.firmwareName.trim()">
											</el-input>
										</el-form-item>
										<el-form-item prop="firmwareLink">
											<el-input v-model="downloadItem.firmwareLink"
												placeholder="请输入Link(http://... 或 https://...)" clearable
												maxlength="2000" class="mr10"
												@blur="downloadItem.firmwareLink=downloadItem.firmwareLink.trim()">
											</el-input>
										</el-form-item>
									</el-form>
									<el-button class="firmware-btn"
										:disabled="!downloadItem.firmwareName || !downloadItem.firmwareLink"
										@click="addDownloadItem"> 添加 </el-button>
								</div>
								<div v-for="(downloadItem, index) in form.firmwareList" :key="index"
									class="link-wrapper">
									<el-tag type="info" closable class="link-tag"
										@close="removeDownloadItem(downloadItem)">
										{{ downloadItem.firmwareName }} : {{ downloadItem.firmwareLink }}
									</el-tag>
								</div>
							</el-form-item>

							<el-form-item label="上传附件">
								<Upload ref="upload" :files.sync="uploadNormalFiles"
									:failedFiles.sync="uploadFailedFiles" class="full-width"></Upload>
							</el-form-item>
						</el-form>
					</div>
				</el-col>

				<el-col :lg="9" :md="9" :sm="9" :xs="24">
					<div class="upload-container pa20 pb0">
						<div class="title-wrapper">
							<div class="title">
								附件内容
							</div>
						</div>

						<div v-if="uploadFiles.length > 0">
							<template v-for="file in uploadFiles">
								<Progress :key="file.uuid" :file="file" :percentage="file.percentage"
									@retry="uploadRetry(file)" @remove="uploadRemove(file)"></Progress>
							</template>
						</div>
						<div v-else class="empty">
							<img :src="assets.emptyImg" class="empty-img" />
							<div class="empty-description">
								暂无附件内容~
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import Upload from '@/components/Upload'
	import RichTextEditor from '@/components/RichText/RichTextEditor'
	import kong from '@/assets/images/kong.png'
	import Progress from '@/components/Progress'
	import {
		debounce
	} from '@/utils/debounce'

	export default {
		components: {
			Upload,
			Progress,
			RichTextEditor
		},
		data() {
			return {
				// 公告ID
				id: '',
				// 新增和编辑后返回列表页，需要还原列表页搜索条件
				cached: {
					pageNo: 1,
					searchKey: '',
					type: '',
					publishFlag: ''
				},
				form: {
					subject: '',
					type: '',
					content: '',
					deviceIdList: [],
					fileIdList: [],
					areaIdList: [],
					firmwareList: []
				},
				downloadItem: {
					firmwareName: '',
					firmwareLink: ''
				},
				// 分类选项
				typeOptions: [],
				// 区域选项
				areaOptions: [],
				// 机型选项
				machineProps: {
					multiple: true,
					emitPath: false,
					value: 'id',
					label: 'name',
					children: 'children',
					disabled: 'disabled'
				},
				// 所有机型选项
				machineOptions: [],
				// 对应el-upload组件的数据
				// 当重新上传时，会清空组件的文件列表数据
				// 正常的上传状态文件列表
				uploadNormalFiles: [],
				// 失败的上传文件列表
				uploadFailedFiles: [],
				// 静态文件
				assets: {
					emptyImg: kong
				},
				rules: {
					subject: [{
							required: true,
							message: '请输入公告主题',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 100,
							message: '公告主题长度须在1-100范围内',
							trigger: 'blur'
						}
					],
					content: [{
						required: true,
						message: '请输入公告内容',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请选择分类',
						trigger: 'change'
					}],
					areaIdList: [{
						required: true,
						message: '请选择区域',
						trigger: 'change'
					}]
				},
				firmwareRules: {
					firmwareLink: [{
						type: 'url',
						message: '请输入正确的地址',
						trigger: 'blur'
					}]
				},
				scrollTop: 0,
				deviceVisible: false
			}
		},
		watch: {
			uploadNormalFiles(files) {
				this.form.fileIdList = []
				for (const file of files) {
					if (file.response && file.response.code === '0') {
						this.form.fileIdList.push(file.response.data.id)
					}
				}
			},
			uploadFailedFiles(files) {}
		},
		computed: {
			// 所有状态的上传文件
			uploadFiles() {
				let files = []

				if (this.uploadNormalFiles) files.push.apply(files, this.uploadNormalFiles)
				if (this.uploadFailedFiles) files.push.apply(files, this.uploadFailedFiles)
				return files
			},
			machines() {
				return this.form.deviceIdList
			},
			title() {
				if (this.id) return '修改公告'
				return '新增公告'
			},
			deviceClass() {
				if (this.deviceVisible) return 'hide-cascader-scroll'
				return 'hide-cascader-scroll-reset'
			}
		},
		created() {
			this.getTypeOptions()
			this.getAreaOptions()
			this.getMachineOptions()

			this.id = this.$route.query.id

			if (this.id) this.getDetail()

			this.cached.pageNo = this.$route.params.pageNo || 1
			this.cached.searchKey = this.$route.params.searchKey || ''
			this.cached.type = this.$route.params.type || ''
			if (this.$route.params.publishFlag === 0) {
				this.cached.publishFlag = this.$route.params.publishFlag
			} else {
				this.cached.publishFlag = this.$route.params.publishFlag || ''
			}
		},
		methods: {
			// 获取公告数据
			getDetail() {
				if (!this.id) return

				let that = this
				that.$request.post(
					"dataNoticeInfo",
					true, {
						id: that.id
					},
					function (r) {
						if (r.code == "0") {
							that.form.subject = r.data.subject || ''
							that.form.areaIdList = r.data.areaIdList || []
							that.form.type = r.data.type || ''
							that.form.content = r.data.content || ''
							that.form.deviceIdList = r.data.deviceIdList || []
							that.form.firmwareList = []
							for (const firmware of r.data.firmwareList) {
								const f = {
									firmwareName: firmware.firmwareName,
									firmwareLink: firmware.firmwareLink
								}
								that.form.firmwareList.push(f)
							}

							that.uploadNormalFiles = []
							for (const file of r.data.sysAttachFileList) {
								file.name = file.fileName
								file.status = 'success'
								file.percentage = 100
								file.response = {
									code: '0',
									data: {
										id: file.id
									}
								}
								that.uploadNormalFiles.push(file)
							}
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			},
			// 获取分类信息
			getTypeOptions() {
				let that = this
				that.$request.post(
					"sysDicCodeList",
					true, {
						code: 'noticeType'
					},
					function (r) {
						if (r.code == "0") {
							that.typeOptions = r.data
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			},
			// 获取区域数据
			getAreaOptions() {
				let that = this
				that.$request.post(
					"sysDicCodeList",
					true, {
						code: 'areaType'
					},
					function (r) {
						if (r.code == "0") {
							that.areaOptions = r.data
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			},
			// 获取所有机型数据
			getMachineOptions() {
				let that = this
				that.$request.post(
					"getModelDeviceSim",
					true, {},
					function (r) {
						if (r.code == "0") {
							that.machineOptions = r.data
						} else {
							that.$message.error(r.msg);
						}
					}
				)
			},
			// 添加固件下载地址
			addDownloadItem() {
				this.downloadItem.firmwareName = this.downloadItem.firmwareName.trim()
				this.downloadItem.firmwareLink = this.downloadItem.firmwareLink.trim()

				this.$refs['firmware-form'].validate((valid) => {
					if (valid) {
						const obj = Object.assign({}, this.downloadItem)
						this.form.firmwareList.push(obj)
						this.downloadItem.firmwareName = ''
						this.downloadItem.firmwareLink = ''
					} else {
						return false
					}
				})
			},
			// 删除固件下载地址
			removeDownloadItem(item) {
				this.form.firmwareList.splice(this.form.firmwareList.indexOf(item), 1)
			},
			// 上传失败重试
			uploadRetry(file) {
				this.uploadFailedFiles.splice(this.uploadFailedFiles.indexOf(file), 1)
				file.status = 'ready'
				this.uploadNormalFiles.push(file)
				this.$refs.upload.submit()
			},
			// 取消上传
			uploadRemove(file) {
				// this.$refs.upload.abort(file)
				// this.uploadNormalFiles.splice(this.uploadNormalFiles.indexOf(file), 1)
				if (this.uploadNormalFiles.indexOf(file) > -1) {
					this.uploadNormalFiles.splice(this.uploadNormalFiles.indexOf(file), 1)
				}
				if (this.uploadFailedFiles.indexOf(file) > -1) {
					this.uploadFailedFiles.splice(this.uploadFailedFiles.indexOf(file), 1)
				}
			},
			// 新增|修改公告
			submit: debounce(function () {
				this.form.subject = this.form.subject.trim()

				this.$refs['form'].validate((valid) => {
					if (valid) {
						if (this.id) {
							// 修改
							const data = Object.assign(this.form, {
								id: this.id
							})

							let that = this
							that.$request.post(
								"dataNoticeUpdate",
								true,
								data,
								function (r) {
									if (r.code == "0") {
										that.$message.success('保存成功')
										that.$router.go(-1)
									} else {
										that.$message.error(r.msg)
									}
								}
							)
						} else {
							// 新增
							let that = this

							that.$request.post(
								"dataNoticeAdd",
								true,
								that.form,
								function (r) {
									if (r.code == "0") {
										that.$message.success('保存成功')
										that.$router.go(-1)
									} else {
										that.$message.error(r.msg)
									}
								}
							)
						}
					} else {
						return false
					}
				})
			}, 1000),
			goBack() {
				this.$router.push({
					name: 'bulletin',
					params: {
						pageNo: this.cached.pageNo,
						searchKey: this.cached.searchKey,
						type: this.cached.type,
						publishFlag: this.cached.publishFlag
					}
				})
			},
			// 解决cascader组件margin-bottom: -17px 失效的问题
			deviceCascaderVisableChange(visible) {
				this.deviceVisible = visible
			},
			// 处理吸顶时title隐藏
			handleScroll() {
				this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			}
		},
		mounted() {
			window.addEventListener('scroll',this.handleScroll);
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleScroll)
		}
	}

</script>

<style lang="scss" scoped>
	@media screen and (max-width: 767px) {
		.content-row {
			flex-direction: column;
		}
	}

</style>
